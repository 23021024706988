import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    public afa: AngularFireAuth,
    public afd: AngularFireDatabase,
  ) { }

  async login(user: { email: string, password: string }) {
    this.afa.authState.subscribe((data) => {
    });
    const res = await this.afa.auth.signInWithEmailAndPassword(user.email, user.password);
    return res;
  }

  async logout() {
    await this.afa.auth.signOut();
  }
  async createUser(user: { email: string, password: string }) {
    const res = await this.afa.auth.createUserWithEmailAndPassword(user.email, user.password);
    return res;
  }

  get authenticated(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.afa.authState
        .subscribe((authState: firebase.User) => {
          (authState) ? resolve(true) : reject(false);
        });

    });
  }
}
