// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const PRODUCTION_API_KEY = 'AIzaSyDGtOmQSSUdEj6BQHD6Mmmh5hJUBLjxdV8';
const PRODUCTION_AUTH_DOMAIN = 'project-482087131936180168.firebaseapp.com';
const PRODUCTION_DATABASE_URL = 'project-482087131936180168.firebaseio.com';
const PRODUCTION_PROJECT_ID = 'project-482087131936180168';
const PRODUCTION_STORAGE_BUCKET = 'project-482087131936180168.appspot.com';

const DEV_API_KEY = 'AIzaSyDj-LwrRmjxSEnw4BQU2YA3JCTyukebnIA';
const DEV_AUTH_DOMAIN = 'pglv-dev.firebaseapp.com';
const DEV_DATABASE_URL = 'pglv-dev.firebaseio.com';
const DEV_PROJECT_ID = 'pglv-dev';
const DEV_STORAGE_BUCKET = 'pglv-dev.appspot.com';

const DEV_CFUNCTIONS = 'https://us-central1-pglv-dev.cloudfunctions.net';
const PRODUCTION_CFUNCTIONS = 'https://us-central1-project-482087131936180168.cloudfunctions.net';

export const environment = {
  production: true,
};

export const firebaseConstants = environment.production ? {
  apiKey: PRODUCTION_API_KEY,
  authDomain: PRODUCTION_AUTH_DOMAIN,
  databaseURL: PRODUCTION_DATABASE_URL,
  projectId: PRODUCTION_PROJECT_ID,
  storageBucket: PRODUCTION_STORAGE_BUCKET,
} : {
    apiKey: DEV_API_KEY,
    authDomain: DEV_AUTH_DOMAIN,
    databaseURL: DEV_DATABASE_URL,
    projectId: DEV_PROJECT_ID,
    storageBucket: DEV_STORAGE_BUCKET,
  };

export const baseUrl = environment.production ? PRODUCTION_CFUNCTIONS : DEV_CFUNCTIONS;

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
