import { AuthService } from './services/auth/auth.service';
import { Component } from '@angular/core';

import { Platform, LoadingController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    public auth: AuthService,
    public loadingCtrl: LoadingController,
    private platform: Platform,
    public router: Router,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      const loading = this.showLoading();

      this.auth.afa.authState.subscribe(state => {
        if (state) {
          loading.then(ld => ld.dismiss());
          this.router.navigateByUrl('/home');
        } else {
          loading.then(ld => ld.dismiss());
          this.router.navigateByUrl('/login');
        }
      });
    });
  }

  async showLoading() {
    const ld = await this.loadingCtrl.create({
      animated: true,
      spinner: 'crescent',
      message: 'Carregando...'
    });
    ld.present();

    return ld;
  }
}
